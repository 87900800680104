@import "/node_modules/bootstrap/scss/bootstrap.scss";
@import "/node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
$fa-font-path: "/node_modules/@fortawesome/fontawesome-free/webfonts";
@import "/node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
@import "/node_modules/@fortawesome/fontawesome-free/scss/solid.scss";

body { 
    padding-top: 60px;
}

.navbar {
    background-color: hsl(205, 87%, 94%);
}
.content { 
    min-height: calc(100vh - 60px);
}


.navbar-brand {
    font-weight: bold;
}